
.QuizCard {
    min-height: 500px;
}

.QuizHeader {
    text-align: center;
}

.QuizBody {
    background-color: #e7ebf0;
}



.View {
    margin: 25px;
}

.QuizButton {
    text-align: left;
    margin-bottom: 15px;
}

.ButtonImage {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.CheckBox {
    margin-right: 15px;
}