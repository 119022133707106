.MinimapPoint {
    position: absolute;
    cursor: pointer;
    transform: translate(-50%, -100%);

    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
}

.MaximapPoint {
    position: absolute;
    cursor: pointer;
    transform: translate(-50%, -100%);

    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
    border-style: solid;
    border-width: thin;
    border-color: gray;
}