body {
    margin: 0;

    overflow: auto;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overscroll-behavior-x: none;
}

.custom-white {
    background-color: #f4f5f7;
}

.myTable {
    background-color: white
}


html {
    overscroll-behavior-x: none;


}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noWrap {
    white-space: nowrap;
}

.not-draggable {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


