@media screen {

    .EndGuiWrapper {
        padding: 15px;
        width: 100%;
        height: 100%;

        position: absolute;

        max-width: 1200px;

        left: 50%;
        transform: translate(-50%);

        background: #5F6368;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;

    }

    .ExportButtons {

    }

    .EndGuiBackground {

    }
}


@media print {

    @page {
        size: a4;
        /*margin: .5rem 0;*/
    }

    .EndGuiBackground {
        display: none;
    }

    .ExportButtons {
        display: none;
    }

    .EndGuiWrapper {
        width: 800px;

        overflow-x: hidden;
        overflow-y: hidden;
    }


}

.BrandingImage {
    width: 25px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
}

