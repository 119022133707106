
.LabelFrame {
    margin: 15px;
}

.LabelHeader {
    font-size: 35px;
}

.LabelBody {
    font-size: 25px;
}