
img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

html {
    /*user-drag: none;*/
    /*user-select: none;*/
    /*-moz-user-select: none;*/
    /*-webkit-user-drag: none;*/
    /*-webkit-user-select: none;*/
    /*-ms-user-select: none;*/
}


.custom-white {
    background-color: #f4f5f7;
}

.gl-canvas {
    touch-action: none;
}

.app_background {
    background-color: #f4f5f7;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -100;
}

