.image-zoom {
    position: relative;

}

.zoomable-image {
    transition: transform 0.3s;
}

.zoomable-image.zoomed {
    transform: scale(2) translate(-50%, -50%);

    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
}