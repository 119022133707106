
.VideoPlayerWrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;

    left: 0px;
    top: 0px;
}


.VideoPlayer {
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}


.auto-resizable-iframe {
    max-width: 420px;
    margin: 0px auto;

}

.auto-resizable-iframe > div {
    position: relative;
    padding-bottom: 75%;
    height: 0px;
}

.auto-resizable-iframe video {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

}